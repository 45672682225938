import { domReady } from '@';
import { init } from 'ptype/page/main_functions';
domReady(() => {
  let screenWidth = document.body.clientWidth;
  // Set offset because of the height of the client's header
  let offset = screenWidth > 1100 ? 152 : 63;
  let scrollConfig = {
    duration: 500,
    easing: 'ease',
    offset: -offset,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: true,
    y: true,
  };
  init({ scrollConfig });
});
